import { ChangeDetectionStrategy, Component } from '@angular/core';
import { NgIconComponent, provideIcons } from '@ng-icons/core';
import { iconoirWifiOff } from '@ng-icons/iconoir';

@Component({
  selector: 'lib-offline',
  standalone: true,
  imports: [NgIconComponent],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [provideIcons({ iconoirWifiOff })],
  template: `
    <div class="flex size-full flex-col items-center justify-center">
      <ng-icon name="iconoirWifiOff" size="5rem" class="mb-4 text-gray-600" />

      <h1 class="mb-2 text-3xl font-bold text-gray-700">You are offline</h1>
      <p class="text-gray-500">Please check your internet connection and try again.</p>

      <!-- <button
        class="bg-primary-500 hover:bg-primary-600 mt-6 rounded px-4 py-2 text-white disabled:cursor-not-allowed disabled:bg-gray-300"
        (click)="onReloadPage()">
        Reload
      </button> -->
    </div>
  `,
})
export class OfflineComponent {
  // TODO add this if PWA, if not it will be remove the page if connection is not present
  // private window = inject(DOCUMENT).defaultView;
  // onReloadPage() {
  //   this.window?.location.reload();
  // }
}
