import { CdkMenu, CdkMenuGroup, CdkMenuItem, CdkMenuTrigger } from '@angular/cdk/menu';
import { ConnectedPosition } from '@angular/cdk/overlay';
import { NgClass } from '@angular/common';
import { ChangeDetectionStrategy, Component, input, output } from '@angular/core';
import { RouterLink, RouterLinkActive } from '@angular/router';
import { NgIconComponent, provideIcons } from '@ng-icons/core';
import { iconoirMenu } from '@ng-icons/iconoir';
import { DropdownEnterLeave, InitialsPipe, MenuItem } from '@wheere-front/shared-util';

@Component({
  selector: 'lib-avatar-menu',
  standalone: true,
  imports: [
    RouterLink,
    RouterLinkActive,
    CdkMenuGroup,
    CdkMenu,
    CdkMenuItem,
    CdkMenuTrigger,
    NgClass,
    NgIconComponent,
    InitialsPipe,
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
  animations: [DropdownEnterLeave],
  providers: [provideIcons({ iconoirMenu })],
  template: `
    <button
      [cdkMenuTriggerFor]="menu"
      [cdkMenuPosition]="menuPosition"
      #cdkMenuTriggerFor="cdkMenuTriggerFor"
      type="button"
      [ngClass]="{ 'shadow lg:shadow-none': cdkMenuTriggerFor.isOpen }"
      class="lg:focus:ring-primary-100 flex items-center justify-center gap-2 rounded-full border border-gray-200 bg-white p-1 shadow transition-colors ease-in hover:shadow-md active:shadow-sm active:outline-none lg:p-0 lg:focus:ring-4">
      <span class="sr-only">Account menu</span>
      <div class="pl-1 lg:hidden">
        <div class="h-5 w-5">
          <ng-icon name="iconoirMenu" />
        </div>
      </div>
      <div class="bg-primary-500 relative inline-flex h-9 w-9 items-center justify-center overflow-hidden rounded-full">
        <span class="font-medium uppercase text-white">{{ fullname() | initials }}</span>
      </div>
    </button>

    <ng-template #menu>
      @defer (when cdkMenuTriggerFor.isOpen()) {
        <div
          cdkMenu
          @DropdownEnterLeave
          class="mt-2 w-52 divide-y divide-gray-100 rounded-lg border border-gray-100 bg-white shadow-md">
          <div class="px-4 py-3 text-sm text-gray-900">
            <div class="truncate font-semibold">{{ fullname() }}</div>
            <div class="truncate font-medium">{{ email() }}</div>
          </div>
          @if (menuItems()?.length) {
            <ul cdkMenuGroup class="py-2 text-sm text-gray-700 lg:hidden">
              @for (item of menuItems(); track $index) {
                <li cdkMenuItem>
                  <a
                    [routerLink]="'./' + item.path"
                    routerLinkActive="font-bold cursor-default hover:bg-white"
                    [routerLinkActiveOptions]="{ exact: true }"
                    class="block px-4 py-2 hover:bg-gray-100">
                    {{ item.label }}
                  </a>
                </li>
              }
            </ul>
          }
          @if (withAccount()) {
            <div cdkMenuGroup class="py-2">
              <a
                cdkMenuItem
                routerLink="/account"
                routerLinkActive="font-bold cursor-default hover:bg-white"
                class="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100">
                Account
              </a>
            </div>
          }
          <div cdkMenuGroup class="py-2">
            <button
              cdkMenuItem
              (click)="logout.emit()"
              class="w-full px-4 py-2 text-left text-sm text-gray-700 hover:bg-gray-100">
              Sign out
            </button>
          </div>
        </div>
      }
    </ng-template>
  `,
})
export class AvatarMenuComponent {
  fullname = input.required<string>();
  email = input.required<string | undefined>();
  withAccount = input(true);
  menuItems = input<MenuItem[]>();
  logout = output<void>();

  readonly menuPosition: ConnectedPosition[] = [
    {
      originX: 'end',
      originY: 'bottom',
      overlayX: 'end',
      overlayY: 'top',
    },
  ];
}
