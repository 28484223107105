import { NgClass, NgTemplateOutlet } from '@angular/common';
import {
  ChangeDetectionStrategy,
  Component,
  Input,
  OnChanges,
  OnInit,
  SimpleChanges,
  TemplateRef,
} from '@angular/core';
import { TooltipAnimation } from './tooltip-animation';
import { TooltipPosition } from './tooltip.directive';

@Component({
  standalone: true,
  selector: 'lib-tooltip',
  imports: [NgTemplateOutlet, NgClass],
  animations: [TooltipAnimation],
  changeDetection: ChangeDetectionStrategy.OnPush,
  template: `
    <div @TooltipAnimation role="tooltip" aria-label="Tooltip">
      <ng-template #defaultTemplate>
        <div
          class="relative rounded-lg border border-gray-200 bg-white px-3 py-2 text-sm shadow">
          {{ text }}
          @if (withCaret) {
            <div [ngClass]="caret" class="size-2 bg-gray-800"></div>
          }
        </div>
      </ng-template>

      <ng-container
        [ngTemplateOutlet]="template || defaultTemplate"
        [ngTemplateOutletContext]="templateCtx"></ng-container>
    </div>
  `,
  styles: [
    `
      .caret-left {
        @wuily absolute left-0 top-[50%] -z-10 -translate-x-[35%] -translate-y-[50%] rotate-45 rounded-sm;
      }

      .caret-right {
        @wuily absolute right-0 top-[50%] -z-10 -translate-y-[50%] translate-x-[35%] rotate-45 rounded-sm;
      }

      .caret-top {
        @wuily absolute left-[50%] top-0 -z-10 -translate-x-[50%] -translate-y-[35%] rotate-45 rounded-sm;
      }

      .caret-bottom {
        @wuily absolute bottom-0 left-[50%] -z-10 -translate-x-[50%] translate-y-[35%] rotate-45 rounded-sm;
      }
    `,
  ],
})
export class TooltipComponent implements OnInit, OnChanges {
  @Input() text: string | null = null;
  @Input() withCaret = false;
  @Input() tooltipClass: string | null = null;
  @Input() template: TemplateRef<unknown> | null = null;
  @Input() templateCtx: unknown;
  @Input() position: TooltipPosition | null = null;

  caret = '';

  ngOnInit(): void {
    if (!this.text && !this.template) {
      throw Error(
        'Tooltip directive need to have a text or a template input to work!'
      );
    }

    if (this.templateCtx && !this.template) {
      throw Error('Tooltip template context need to have a template to work!');
    }

    if (this.withCaret) {
      this.getCaretPosition();
    }
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['position'] && this.withCaret) {
      this.getCaretPosition();
    }
  }

  private getCaretPosition() {
    if (this.position === 'top') {
      this.caret = 'caret-bottom';
    }

    if (this.position === 'bottom') {
      this.caret = 'caret-top';
    }

    if (this.position === 'left') {
      this.caret = 'caret-right';
    }

    if (this.position === 'right') {
      this.caret = 'caret-left';
    }
  }
}
