import { Directive, ElementRef, Renderer2 } from '@angular/core';

@Directive({
  selector: '[libSelect]',
  standalone: true,
})
export class SelectDirective {
  constructor(private el: ElementRef, private renderer: Renderer2) {
    const classes = [
      'w-full',
      'md:w-40',
      'p-2',
      'border',
      'border-gray-300',
      'rounded-lg',
      'focus:ring-pink-500',
      'focus:border-pink-500',
    ];
    classes.forEach((className) => {
      this.renderer.addClass(this.el.nativeElement, className);
    });
  }
}
