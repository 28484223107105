import { A11yModule } from '@angular/cdk/a11y';
import { DIALOG_DATA, DialogRef } from '@angular/cdk/dialog';
import { ChangeDetectionStrategy, Component, inject } from '@angular/core';
import { ButtonDirective } from './button.directive';
import { CardModule } from './card/index';

@Component({
  selector: 'lib-confirmation-dialog',
  standalone: true,
  imports: [A11yModule, CardModule, ButtonDirective],
  template: `
    <div cdkTrapFocus libCard>
      <div libCardHeader>
        <h3 libCardTitle>{{ dialogData.title }}</h3>
        @if (dialogData.description; as description) {
          <p libCardDescription>{{ description }}</p>
        }
      </div>

      <div libCardFooter class="flex justify-end">
        <button libBtn (click)="dialogRef.close(false)" variant="outline">
          No, cancel
        </button>
        <button libBtn (click)="dialogRef.close(true)" cdkFocusInitial>
          Yes, I'm sure
        </button>
      </div>
    </div>
  `,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ConfirmationDialogComponent {
  dialogData: { title: string; description?: string } = inject(DIALOG_DATA);
  dialogRef = inject(DialogRef);

  constructor() {
    if (!this.dialogData?.title)
      throw Error('ConfirmationDialogComponent has no title!');
  }
}
