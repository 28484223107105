import {
  ChangeDetectionStrategy,
  Component,
  computed,
  input,
} from '@angular/core';
import { mergeClasses } from '@wheere-front/shared-util';
import { cva } from 'class-variance-authority';
import { ClassValue } from 'clsx';

const formFieldVariants = cva('block pb-1.5 w-full', {
  variants: {},
  defaultVariants: {},
});

@Component({
  selector: 'lib-form-field',
  standalone: true,
  template: `<div class="relative size-full"><ng-content /></div>`,
  changeDetection: ChangeDetectionStrategy.OnPush,
  host: {
    '[class]': '_computedClass()',
  },
})
export class FormFieldComponent {
  readonly userClass = input<ClassValue>('', { alias: 'class' });

  protected _computedClass = computed(() =>
    mergeClasses(formFieldVariants(), this.userClass())
  );
}
