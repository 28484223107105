import {
  ChangeDetectionStrategy,
  Component,
  computed,
  ElementRef,
  input,
  output,
  viewChild,
} from '@angular/core';
import { NgIconComponent } from '@ng-icons/core';
import { InputModule } from './form/index';

type FileType = 'json' | 'csv';

@Component({
  selector: 'lib-upload',
  standalone: true,
  imports: [InputModule, NgIconComponent],
  changeDetection: ChangeDetectionStrategy.OnPush,
  template: `
    <lib-form-field>
      <lib-label class="font-medium text-gray-900" for="file_input">
        <span class="sr-only">Upload file</span>
      </lib-label>
      <div class="relative h-fit w-80">
        <input
          libInput
          #input
          type="file"
          [accept]="accept()"
          id="file_input"
          (change)="onSelectFile($event)"
          class="size-full cursor-pointer rounded-lg border-2 border-gray-300 bg-gray-50 text-gray-900 hover:bg-gray-100" />

        <ng-icon
          name="iconoirDownload"
          size="1.25rem"
          class="absolute right-4 top-[50%] -translate-y-[50%]" />
      </div>
    </lib-form-field>
  `,
  host: {
    class: 'block',
  },
})
export class UploadComponent {
  input = viewChild<ElementRef<HTMLInputElement>>('input');
  acceptFiles = input<FileType[]>();
  fileDataString = output<string | null>();

  accept = computed(() =>
    this.acceptFiles()
      ?.map(type => `.${type}`)
      .join(', ')
  );

  onSelectFile(event: Event) {
    const file: File | null | undefined = (
      event.target as HTMLInputElement
    ).files?.item(0);

    if (file) {
      const reader = new FileReader();

      reader.readAsText(file);

      reader.onload = () => {
        try {
          const dataString: string = reader.result as string;
          this.fileDataString.emit(dataString);
        } catch (error) {
          this.fileDataString.emit(null);

          console.error(
            'Error occurred while reading and parsing the json file:',
            error
          );
        }
        (event.target as HTMLInputElement).files = null;
      };

      reader.onerror = error => {
        this.fileDataString.emit(null);
        console.error('Error occurred while reading the file:', error);
        (event.target as HTMLInputElement).files = null;
      };
    }
  }
}
