import { Directive, input } from '@angular/core';
import { DialogComponent } from './dialog.component';

let idSequence = 0;

@Directive({
  selector: 'button[libDialogOpenFor]',
  standalone: true,
  host: {
    '[id]': 'id()',
    '(click)': 'show()',
    'aria-haspopup': 'dialog',
    '[attr.aria-expanded]': 'this.dialog()?.isOpen()',
    '[attr.data-state]': 'this.dialog()?.isOpen()',
    '[attr.aria-controls]': 'idSequence',
  },
  exportAs: 'libDialogOpenFor',
})
export class DialogOpenDirective {
  readonly id = input(`dialog-show-${idSequence++}`);

  dialog = input.required<DialogComponent | null>({
    alias: 'libDialogOpenFor',
  });

  show() {
    this.dialog()?.show();
  }
}
