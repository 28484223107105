import { ChangeDetectionStrategy, Component, input } from '@angular/core';
import { RouterLink } from '@angular/router';

@Component({
  selector: 'lib-heroe',
  standalone: true,
  imports: [RouterLink],
  changeDetection: ChangeDetectionStrategy.OnPush,
  template: `
    <ng-content />
    <p class="mb-4 text-3xl font-bold tracking-tight text-gray-900 md:text-4xl">
      {{ title() }}
    </p>
    @if (subtitle()) {
      <p class="mb-4 text-lg font-light text-gray-500">
        {{ subtitle() }}
      </p>
    }

    @if (cta(); as cta) {
      <a
        [routerLink]="cta.url"
        class="bg-primary-500 hover:bg-primary-700 focus:ring-primary-200 my-4 inline-flex rounded-lg px-5 py-2.5 text-center text-sm font-medium text-white focus:outline-none focus:ring-4"
        >{{ cta.label }}</a
      >
    }
  `,
  host: {
    class:
      'mx-auto flex h-full max-w-screen-sm flex-col items-center justify-center text-center translate -translate-y-[8%]',
  },
})
export class HeroeComponent {
  title = input.required<string>();
  subtitle = input<string>();
  cta = input<{ label: string; url: string }>();
}
