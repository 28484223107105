import { Directive, computed, input } from '@angular/core';
import { mergeClasses } from '@wheere-front/shared-util';
import { cva, type VariantProps } from 'class-variance-authority';
import type { ClassValue } from 'clsx';

export const cardTitleVariants = cva('text-xl font-semibold leading-none tracking-tight truncate', {
  variants: {
    size: {
      sm: 'text-lg',
      default: '',
      lg: 'text-2xl',
    },
  },
  defaultVariants: {
    size: 'default',
  },
});
export type CardTitleVariants = VariantProps<typeof cardTitleVariants>;

@Directive({
  selector: '[libCardTitle]',
  standalone: true,
  host: {
    '[class]': '_computedClass()',
  },
})
export class CardTitleDirective {
  readonly userClass = input<ClassValue>('', { alias: 'class' });
  readonly size = input<CardTitleVariants['size']>('default');

  protected _computedClass = computed(() => mergeClasses(cardTitleVariants({ size: this.size() }), this.userClass()));
}
