import { NgTemplateOutlet } from '@angular/common';
import {
  ChangeDetectionStrategy,
  Component,
  contentChild,
  contentChildren,
  TemplateRef,
  viewChild,
} from '@angular/core';
import { toObservable } from '@angular/core/rxjs-interop';
import { merge } from 'rxjs';
import { switchMap } from 'rxjs/operators';
import { AutocompleteContentDirective } from './autocomplete-content.directive';
import { OptionComponent } from './option.component';

@Component({
  standalone: true,
  imports: [NgTemplateOutlet],
  selector: 'lib-autocomplete',
  exportAs: 'libAutocomplete',
  changeDetection: ChangeDetectionStrategy.OnPush,
  template: `
    <ng-template #root>
      @defer {
        <div class="w-full overflow-y-scroll rounded bg-white shadow">
          @if (content()?.tpl; as tpl) {
            <ng-container *ngTemplateOutlet="tpl"></ng-container>
          }
        </div>
      }
    </ng-template>
  `,
})
export class AutocompleteComponent {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  rootTemplate = viewChild<TemplateRef<any>>('root');
  content = contentChild(AutocompleteContentDirective);
  options = contentChildren(OptionComponent);

  optionsClick$ = toObservable(this.options).pipe(
    switchMap(options => {
      const clicks$ = options.map(option => option.click$);
      return merge(...clicks$);
    })
  );
}
