import { ChangeDetectionStrategy, Component, input, output, viewChild } from '@angular/core';
import { AlertComponent } from './alert.component';
import { DialogCloseDirective, DialogComponent, DialogOpenDirective } from './dialog/index';

@Component({
  selector: 'lib-alert-dialog',
  standalone: true,
  imports: [DialogComponent, DialogCloseDirective, DialogOpenDirective, AlertComponent],
  changeDetection: ChangeDetectionStrategy.OnPush,
  template: `
    <lib-dialog #dialog>
      <lib-alert
        [title]="title()"
        [description]="description()"
        [confirmLabel]="confirmLabel()"
        (confirm)="confirm.emit()"
        (close)="dialog.hide()" />
    </lib-dialog>
  `,
  host: {
    class: 'block',
  },
})
export class AlertDialogComponent {
  title = input('Are you sure?');
  description = input('This action is irreversible.');
  confirmLabel = input('Yes, continue');
  confirm = output<void>();
  dialog = viewChild<DialogComponent>('dialog');
}
