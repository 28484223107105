import { NgClass } from '@angular/common';
import { booleanAttribute, ChangeDetectionStrategy, Component, input, numberAttribute } from '@angular/core';
import { BadgeDirective } from './badge.directive';
import { ProgressComponent } from './progress.component';

export const trackerBatteryColorClass = (level: number) => {
  if (level > 50) {
    return 'bg-green-100 text-green-800';
  } else if (level > 30) {
    return 'bg-yellow-100 text-yellow-800';
  } else if (level !== 0) {
    return 'bg-red-100 text-red-800';
  } else {
    return 'bg-gray-100 text-gray-800';
  }
};

@Component({
  selector: 'lib-battery',
  standalone: true,
  imports: [ProgressComponent, BadgeDirective, NgClass],
  changeDetection: ChangeDetectionStrategy.OnPush,
  template: `
    @if (isBadge()) {
      <span libBadge [ngClass]="trackerBatteryColorClass(value())">
        Batterie :
        {{ value() + '%' }}
      </span>
    } @else {
      @if (value() > 50) {
        <lib-progress [value]="value()" class="text-green-800" progressColor="bg-green-100" />
      } @else if (value() > 30) {
        <lib-progress [value]="value()" class="text-yellow-800" progressColor="bg-yellow-200" />
      } @else if (value() !== 0) {
        <lib-progress [value]="value()" class="text-red-800" progressColor="bg-red-200" />
      } @else {
        <lib-progress [value]="value()" class="text-gray-800" progressColor="bg-gray-200" />
      }
    }
  `,
})
export class BatteryComponent {
  value = input.required({ transform: numberAttribute });
  isBadge = input(false, { transform: booleanAttribute });
  readonly trackerBatteryColorClass = trackerBatteryColorClass;
}
