import { Component, computed, input } from '@angular/core';
import { mergeClasses } from '@wheere-front/shared-util';
import { ClassValue } from 'clsx';

@Component({
  selector: 'lib-skeleton',
  standalone: true,
  template: '',
  host: {
    '[class]': '_computedClass()',
  },
})
export class SkeletonComponent {
  public readonly userClass = input<ClassValue>('', { alias: 'class' });
  protected _computedClass = computed(() =>
    mergeClasses('block animate-pulse rounded-md bg-gray-100', this.userClass())
  );
}
