import { ChangeDetectionStrategy, Component, ElementRef, inject, input } from '@angular/core';
import { fromEvent, map } from 'rxjs';

@Component({
  selector: 'lib-option',
  standalone: true,
  template: `
    <div class="cursor-pointer p-2 text-sm hover:bg-gray-100">
      <ng-content></ng-content>
    </div>
  `,
  changeDetection: ChangeDetectionStrategy.OnPush,
  styles: `
    :host {
      display: block;
    }

    :host.empty {
      pointer-events: none;
    }
  `,
})
export class OptionComponent<T> {
  value = input<T>();

  private host = inject(ElementRef);

  click$ = fromEvent(this.host.nativeElement, 'click').pipe(map(() => this.value()));
}
