import { Directive, computed, input } from '@angular/core';
import { mergeClasses } from '@wheere-front/shared-util';
import { cva, type VariantProps } from 'class-variance-authority';
import type { ClassValue } from 'clsx';

export const cardDescriptionVariants = cva('text-base text-muted-foreground', {
  variants: {},
  defaultVariants: {},
});
export type CardDescriptionVariants = VariantProps<
  typeof cardDescriptionVariants
>;

@Directive({
  selector: '[libCardDescription]',
  standalone: true,
  host: {
    '[class]': '_computedClass()',
  },
})
export class CardDescriptionDirective {
  readonly userClass = input<ClassValue>('', { alias: 'class' });

  protected _computedClass = computed(() =>
    mergeClasses(cardDescriptionVariants(), this.userClass())
  );
}
