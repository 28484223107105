import { A11yModule } from '@angular/cdk/a11y';
import { ChangeDetectionStrategy, Component, input, output } from '@angular/core';
import { ButtonDirective } from './button.directive';
import { CardModule } from './card/index';

@Component({
  selector: 'lib-alert',
  standalone: true,
  imports: [CardModule, ButtonDirective, A11yModule],
  changeDetection: ChangeDetectionStrategy.OnPush,
  template: `
    <div libCard class="w-80">
      <div libCardHeader>
        <h3 libCardTitle>{{ title() }}</h3>
        <p libCardDescription>{{ description() }}</p>
      </div>

      <div libCardFooter class="flex justify-end">
        <button libBtn (click)="onClose()" (keydown.enter)="onClose()" variant="outline">Cancel</button>
        <button libBtn (click)="onConfirm()" (keydown.enter)="onConfirm()" cdkFocusInitial>
          {{ confirmLabel() }}
        </button>
      </div>
    </div>
  `,
  host: {
    class: 'block',
  },
})
export class AlertComponent {
  title = input('Are you sure?');
  description = input('This action is irreversible.');
  confirmLabel = input('Yes, continue');
  confirm = output<void>();
  close = output<void>();

  onConfirm() {
    this.confirm.emit();
  }

  onClose() {
    this.close.emit();
  }
}
