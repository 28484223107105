import { NgClass } from '@angular/common';
import {
  ChangeDetectionStrategy,
  Component,
  input,
  OnChanges,
  signal,
  SimpleChanges,
} from '@angular/core';
import { TooltipDirective } from './tooltip/tooltip.directive';

type Color = 'GREEN' | 'RED' | 'ORANGE' | 'YELLOW' | 'BLUE' | 'GRAY';

@Component({
  selector: 'lib-token',
  standalone: true,
  imports: [NgClass, TooltipDirective],
  styles: [
    `
      .green-status {
        @wuily relative inline-flex h-10 w-10 items-center justify-center rounded-full bg-green-100 text-center text-sm text-green-800;
      }
      .red-status {
        @wuily relative inline-flex h-10 w-10 rounded-full bg-red-100 text-red-800;
      }
      .yellow-status {
        @wuily relative inline-flex h-10 w-10 rounded-full bg-yellow-100 text-yellow-800;
      }
      .orange-status {
        @wuily relative inline-flex h-10 w-10 rounded-full bg-orange-100 text-orange-800;
      }
      .gray-status {
        @wuily relative inline-flex h-10 w-10 rounded-full bg-gray-100 text-gray-800;
      }
      .green-status-halo {
        @wuily absolute inline-flex h-full w-full rounded-full bg-green-400 opacity-75;
      }
      .red-status-halo {
        @wuily absolute inline-flex h-full w-full rounded-full bg-red-400 opacity-75;
      }
      .yellow-status-halo {
        @wuily absolute inline-flex h-full w-full rounded-full bg-yellow-400 opacity-75;
      }
      .orange-status-halo {
        @wuily absolute inline-flex h-full w-full rounded-full bg-orange-400 opacity-75;
      }
      .gray-status-halo {
        @wuily absolute inline-flex h-full w-full rounded-full bg-gray-400 opacity-75;
      }
    `,
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
  template: `
    @if (text()?.length) {
      <span
        class="relative flex h-10 w-10 cursor-pointer"
        libTooltip
        [libTooltipTemplate]="tooltip"
        libTooltipPosition="top"
        libTooltipPositionOffset="8"
        libTooltipMode="hover">
        <span
          [ngClass]="{
            'animate-ping1': animating(),
            'green-status-halo': color() === 'GREEN',
            'red-status-halo': color() === 'RED',
            'yellow-status-halo': color() === 'YELLOW',
            'orange-status-halo': color() === 'ORANGE',
            'gray-status-halo': color() === 'GRAY',
          }"></span>
        <span
          [ngClass]="{
            'green-status': color() === 'GREEN',
            'red-status': color() === 'RED',
            'yellow-status': color() === 'YELLOW',
            'orange-status': color() === 'ORANGE',
            'gray-status': color() === 'GRAY',
          }">
          {{ text() }}
        </span>
      </span>

      <ng-template #tooltip let-date="date">
        @if (tooltipText(); as text) {
          <div
            class="rounded-lg border border-gray-200 bg-white px-3 py-2 text-sm shadow">
            {{ text }}
          </div>
        }
      </ng-template>
    }
  `,
  host: {
    class: 'block',
  },
})
export class TokenComponent implements OnChanges {
  text = input.required<string | undefined>();
  tooltipText = input<string | undefined>();
  color = input<Color>('GRAY');

  animating = signal(false);

  ngOnChanges(changes: SimpleChanges) {
    if (changes['text'] && !changes['text'].isFirstChange()) {
      this.resetAnimation();
    }
  }

  resetAnimation() {
    this.animating.set(true);
    setTimeout(() => {
      this.animating.set(false);
    }, 2000);
  }
}
