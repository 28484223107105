import { ConnectionPositionPair, Overlay, OverlayRef } from '@angular/cdk/overlay';
import { TemplatePortal } from '@angular/cdk/portal';
import {
  DestroyRef,
  Directive,
  ElementRef,
  inject,
  input,
  OnInit,
  output,
  signal,
  ViewContainerRef,
} from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { filter, fromEvent, takeUntil, tap } from 'rxjs';
import { AutocompleteComponent } from './autocomplete.component';

@Directive({
  standalone: true,
  selector: '[libAutocomplete]',
})
export class AutocompleteDirective implements OnInit {
  libAutocomplete = input.required<AutocompleteComponent>();
  libAutocompleteBlur = output<void>();
  libAutocompleteChange = output<any>();

  private overlayRef = signal<OverlayRef | undefined>(undefined);

  private host = inject(ElementRef<HTMLInputElement>);
  private vcr = inject(ViewContainerRef);
  private overlay = inject(Overlay);
  private destroyRef = inject(DestroyRef);

  ngOnInit() {
    fromEvent(this.origin, 'focus')
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe(() => {
        this.openDropdown();

        this.libAutocomplete()
          .optionsClick$.pipe(takeUntil(this.overlayRef()!.detachments()))
          .subscribe((value: unknown) => {
            this.libAutocompleteChange.emit(value);
            this.onClose();
          });
      });
  }

  openDropdown() {
    this.overlayRef.set(
      this.overlay.create({
        width: this.origin.offsetWidth,
        maxHeight: 40 * 3,
        backdropClass: '',
        scrollStrategy: this.overlay.scrollStrategies.reposition(),
        positionStrategy: this.getOverlayPosition(),
      })
    );

    if (this.overlayRef()) {
      const templateRef = this.libAutocomplete().rootTemplate();

      if (templateRef) {
        const template = new TemplatePortal(templateRef, this.vcr);
        this.overlayRef()?.attach(template);
        overlayClickOutside(this.overlayRef()!, this.origin)
          .pipe(takeUntilDestroyed(this.destroyRef))
          .subscribe(() => this.onClose());

        this.overlayRef()
          ?.keydownEvents()
          .pipe(
            filter(e => e.key === 'Escape'),
            tap(() => this.onClose()),
            takeUntilDestroyed(this.destroyRef)
          )
          .subscribe();
      }
    }
  }

  private onClose() {
    this.overlayRef()?.detach();
    this.overlayRef.set(undefined);
    this.libAutocompleteBlur.emit();
  }

  private getOverlayPosition() {
    const positions = [
      new ConnectionPositionPair({ originX: 'start', originY: 'bottom' }, { overlayX: 'start', overlayY: 'top' }),
      new ConnectionPositionPair({ originX: 'start', originY: 'top' }, { overlayX: 'start', overlayY: 'bottom' }),
    ];

    return this.overlay
      .position()
      .flexibleConnectedTo(this.origin)
      .withPositions(positions)
      .withFlexibleDimensions(true)
      .withPush(true);
  }

  get origin() {
    return this.host.nativeElement;
  }
}

export function overlayClickOutside(overlayRef: OverlayRef, origin: HTMLElement) {
  return fromEvent<MouseEvent>(document, 'click').pipe(
    filter(event => {
      const clickTarget = event.target as HTMLElement;
      const notOrigin = clickTarget !== origin; // the input
      const notOverlay = !!overlayRef && overlayRef.overlayElement.contains(clickTarget) === false; // the autocomplete
      return notOrigin && notOverlay;
    }),
    takeUntil(overlayRef.detachments())
  );
}
